import { useEffect } from "react";

function App() {
  useEffect(() => {
    window.location.href = "https://abdullahmazumder.vercel.app/";
  }, []);
  return <div className="App"></div>;
}

export default App;
